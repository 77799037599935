var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row",staticStyle:{"width":"100%","padding-left":"10px"}},[_c('div',{class:_vm.showFrameset && _vm.showForm ? 'col-8' : 'col-12'},[(_vm.showForm)?_c('q-card',{staticClass:"my-card",staticStyle:{"margin-bottom":"10px"},attrs:{"flat":"","bordered":""}},[_c('q-card-section',{staticClass:"q-pt-none"},[(_vm.formName)?_c('pro-tender-form-render',{attrs:{"mode":"View","code":_vm.formName,"tenderId":_vm.tenderId,"formDataPromise":_vm.formDataPromise()}}):_vm._e()],1)],1):_vm._e()],1),_c('div',{class:_vm.showFrameset && _vm.showForm ? 'col-4' : 'col-12',style:(_vm.showFrameset && _vm.showForm ? 'padding-left: 10px;' : '')},[_c('q-card',{staticClass:"my-card",attrs:{"flat":"","bordered":""}},[_c('q-card-section',{staticClass:"q-pt-xs"},[_c('div',{staticClass:"text-h4 q-mt-sm q-mb-xs"},[_vm._v(" "+_vm._s(_vm.getRes("Form.Col.Information"))+" ")]),_c('div',{staticClass:"q-mt-sm q-mb-xs"},[_c('q-markup-table',{attrs:{"dense":"","flat":"","separator":"none"}},[_c('thead'),_c('tbody',[_c('tr',{staticClass:"q-tr--no-hover"},[_c('td',{staticStyle:{"width":"200px"}},[_vm._v(" "+_vm._s(_vm.getRes("Document.Reference"))+": ")]),_c('td',{staticClass:"q-pl-sm",domProps:{"textContent":_vm._s(_vm.reference)}})]),_c('tr',{staticClass:"q-tr--no-hover"},[_c('td',[_vm._v(_vm._s(_vm.getRes("Form.Field.SubjectTitle"))+":")]),_c('td',{staticClass:"q-pl-sm",domProps:{"textContent":_vm._s(_vm.subjectTitle)}})]),_c('tr',{staticClass:"q-tr--no-hover"},[_c('td',[_vm._v(_vm._s(_vm.getRes("CustMenu.IssueDate"))+":")]),_c('td',{staticClass:"q-pl-sm",domProps:{"textContent":_vm._s(
                    _vm.issueDate === null
                      ? ''
                      : _vm.$proSmart.common.getFormattedDate(_vm.issueDate)
                  )}})]),_c('tr',{staticClass:"q-tr--no-hover"},[_c('td',[_vm._v(_vm._s(_vm.getRes("Form.Field.ClosingDate"))+":")]),_c('td',{staticStyle:{"padding-left":"10px"},domProps:{"textContent":_vm._s(
                    _vm.closingDateTime === null
                      ? ''
                      : _vm.$proSmart.common.getFormattedDate(_vm.closingDateTime)
                  )}})])])])],1),_c('div',{staticClass:"text-h4 q-mt-sm q-mb-xs"},[_vm._v(" "+_vm._s(_vm.getRes("Form.Col.TendererInformation"))+" "),_c('q-btn',{staticStyle:{"float":"right"},attrs:{"no-caps":"","dense":"","round":"","flat":"","color":"primary","icon":"edit"},on:{"click":function($event){return _vm.updateTendererEmail()}}})],1),_c('div',{staticClass:"q-mt-sm q-mb-xs"},[_c('q-markup-table',{attrs:{"dense":"","flat":"","separator":"none"}},[_c('thead'),_c('tbody',[_c('tr',{staticClass:"q-tr--no-hover"},[_c('td',{staticStyle:{"width":"200px"}},[_vm._v(" "+_vm._s(_vm.getRes("Form.Field.CompanyName"))+": ")]),_c('td',{staticClass:"q-pl-sm",domProps:{"textContent":_vm._s(_vm.companyName)}})]),_c('tr',{staticClass:"q-tr--no-hover"},[_c('td',[_vm._v(_vm._s(_vm.getRes("Form.Field.EmailAdd"))+":")]),_c('td',{staticClass:"q-pl-sm",domProps:{"textContent":_vm._s(_vm.emailAddress)}})]),_c('tr',{staticClass:"q-tr--no-hover"},[_c('td',[_vm._v(_vm._s(_vm.getRes("Form.Col.SecurityCode"))+":")]),_c('td',{staticClass:"q-pl-sm"},[_c('a',{staticClass:"a-link",attrs:{"href":(_vm.tendererWebUrl + "/TendererLogin?code=" + _vm.securityCode),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.securityCode)+" ")])])])])])],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }