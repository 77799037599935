<template>
  <div style="width: 100%; padding-left: 10px" class="row">
    <div :class="showFrameset && showForm ? 'col-8' : 'col-12'">
      <q-card
        v-if="showForm"
        class="my-card"
        flat
        bordered
        style="margin-bottom: 10px"
      >
        <q-card-section class="q-pt-none">
          <pro-tender-form-render
            v-if="formName"
            mode="View"
            :code="formName"
            :tenderId="tenderId"
            :formDataPromise="formDataPromise()"
          />
        </q-card-section>
      </q-card>
    </div>

    <div
      :class="showFrameset && showForm ? 'col-4' : 'col-12'"
      :style="showFrameset && showForm ? 'padding-left: 10px;' : ''"
    >
      <q-card class="my-card" flat bordered>
        <q-card-section class="q-pt-xs">
          <div class="text-h4 q-mt-sm q-mb-xs">
            {{ getRes("Form.Col.Information") }}
          </div>
          <div class="q-mt-sm q-mb-xs">
            <q-markup-table dense flat separator="none">
              <thead></thead>
              <tbody>
                <tr class="q-tr--no-hover">
                  <td style="width: 200px">
                    {{ getRes("Document.Reference") }}:
                  </td>
                  <td class="q-pl-sm" v-text="reference" />
                </tr>
                <tr class="q-tr--no-hover">
                  <td>{{ getRes("Form.Field.SubjectTitle") }}:</td>
                  <td class="q-pl-sm" v-text="subjectTitle" />
                </tr>
                <tr class="q-tr--no-hover">
                  <td>{{ getRes("CustMenu.IssueDate") }}:</td>
                  <td
                    class="q-pl-sm"
                    v-text="
                      issueDate === null
                        ? ''
                        : $proSmart.common.getFormattedDate(issueDate)
                    "
                  />
                </tr>
                <tr class="q-tr--no-hover">
                  <td>{{ getRes("Form.Field.ClosingDate") }}:</td>
                  <td
                    style="padding-left: 10px"
                    v-text="
                      closingDateTime === null
                        ? ''
                        : $proSmart.common.getFormattedDate(closingDateTime)
                    "
                  />
                </tr>
              </tbody>
            </q-markup-table>
          </div>

          <div class="text-h4 q-mt-sm q-mb-xs">
            {{ getRes("Form.Col.TendererInformation") }}
            <q-btn
              style="float: right"
              no-caps
              dense
              round
              flat
              color="primary"
              icon="edit"
              @click="updateTendererEmail()"
            />
          </div>
          <div class="q-mt-sm q-mb-xs">
            <q-markup-table dense flat separator="none">
              <thead></thead>
              <tbody>
                <tr class="q-tr--no-hover">
                  <td style="width: 200px">
                    {{ getRes("Form.Field.CompanyName") }}:
                  </td>
                  <td class="q-pl-sm" v-text="companyName" />
                </tr>
                <tr class="q-tr--no-hover">
                  <td>{{ getRes("Form.Field.EmailAdd") }}:</td>
                  <td class="q-pl-sm" v-text="emailAddress" />
                </tr>
                <tr class="q-tr--no-hover">
                  <td>{{ getRes("Form.Col.SecurityCode") }}:</td>
                  <td class="q-pl-sm">
                    <a
                      class="a-link"
                      :href="`${tendererWebUrl}/TendererLogin?code=${securityCode}`"
                      target="_blank"
                    >
                      {{ securityCode }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </q-markup-table>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import ProTenderFormRender from "@/components/PROSmart/FormBuilder/ProTenderFormRender";

export default {
  name: "TendererInformation",
  components: {
    ProTenderFormRender,
  },
  props: {
    processId: String,
    tenderId: [String, Number],
    reference: { type: String, default: "" },
    subjectTitle: { type: String, default: "" },
    issueDate: { type: String, default: "" },
    closingDateTime: { type: String, default: "" },
    companyName: { type: String, default: "" },
    emailAddress: { type: String, default: "" },
    securityCode: { type: String, default: "" },
    supplierId: { type: String, default: "" },
    hcmUserId: { type: String, default: "" },
    tendererWebUrl: { type: String, default: "" },
  },
  data() {
    return {};
  },
  methods: {
    updateTendererEmail() {
      this.$router.push({
        name: "updateTendererEmail",
        params: {
          companyName: this.companyName,
          emailAddress: this.emailAddress,
          supplierId: this.supplierId,
          tenderId: this.tenderId,
          hcmUserId: this.hcmUserId,
        },
      });
    },
    formDataPromise() {
      return Promise.resolve(this.modelJson);
    },
  },
};
</script>
