<template>
  <div style="width: 100%">
    <pro-document-breadcrumbs
      :breadcrumbs="breadcrumbs"
      user-role="procurer"
    ></pro-document-breadcrumbs>
    <pro-report
      title="Document.SearchDocument"
      :columns="columns"
      v-model="fullList"
      row-key="id"
      auto-search
      selectable
      :loading="loading"
    >
      <template v-slot:selectedBody="{ row }">
        <TendererInformation
          :process-id="row.securityCode"
          :reference="row.reference"
          is-tenderer
          :subjectTitle="row.subjectTitle"
          :issueDate="row.issueDate"
          :closingDateTime="row.closingDateTime"
          :companyName="row.companyName"
          :emailAddress="row.emailAddress"
          :securityCode="row.securityCode"
          :supplierId="row.supplierId"
          :tenderId="row.tenderId"
          :hcmUserId="row.hcmUserId"
          :tendererWebUrl="tendererWebUrl"
        />
      </template>
    </pro-report>
  </div>
</template>

<script>
import TendererInformation from "@/components/PROSmart/AuditTrail/TendererInformation.vue";
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import ProReport from "@/components/PROSmart/Report/ProReport";

export default {
  components: {
    TendererInformation,
    ProDocumentBreadcrumbs,
    ProReport,
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: "menu.DocumentList",
          icon: "PROSmart-DocumentBox",
        },
      ],
      loading: true,
      columns: [
        {
          name: "reference",
          i18NLabel: "Document.Reference",
          field: "reference",
          align: "left",
          sortable: true,
          style: "width: 200px",
          searchable: true,
          type: "string",
        },
        {
          name: "subjectTitle",
          i18NLabel: "Form.Field.SubjectTitle",
          field: "subjectTitle",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          name: "issueDate",
          i18NLabel: "CustMenu.IssueDate",
          field: "issueDate",
          align: "left",
          sortable: true,
          searchable: true,
          type: "datetime",
          convertTo: "datetime",
        },
        {
          name: "closingDateTime",
          i18NLabel: "Form.Field.ClosingDate",
          field: "closingDateTime",
          align: "left",
          sortable: true,
          searchable: true,
          type: "datetime",
          convertTo: "datetime",
        },
      ],
      fullList: [],
      tendererWebUrl: "",
    };
  },
  methods: {
    multilingualSubjectTitle(subjEng, subjChi) {
      let language = localStorage.getItem("Language");
      if (language !== undefined) {
        switch (language) {
          case "English":
            return subjEng;
          case "Chinese":
            return subjChi;
          default:
            return subjEng;
        }
      }
    },
  },
  beforeMount() {
    this.$proSmart.setting.getWebsiteInfoSetting(this).then((key) => {
      this.tendererWebUrl = key.tendererWebUrl;
    });
  },
  async mounted() {
    this.loading = true;
    // this.search.description = "";
    this.$proSmart.supplierUi.getDocumentList(this).then((b) => {
      this.fullList = b.map((item) => {
        return {
          reference: item.ref,
          subjectTitle: this.multilingualSubjectTitle(
            item.subjEng,
            item.subjChi
          ),
          id: item.accessCode,
          issueDate: item.issueDate,
          closingDateTime: item.closingDate,
          companyName: item.name,
          emailAddress: item.emailAddress.address,
          securityCode: item.accessCode,
          supplierId: item.supplierId,
          tenderId: item.tenderId,
          hcmUserId: item.hcmUserId,
        };
      });
      this.loading = false;
    });
  },
};
</script>
